export const FirebaseConfig = {
	"projectId": "move-773be",
	"appId": "1:430505235194:web:9bd1bf43834131795fef43",
	"databaseURL": "https://move-773be-default-rtdb.firebaseio.com",
	"storageBucket": "move-773be.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyBQdg_psPVO9R3BXK0t-raC1amxqBf4q2I",
	"authDomain": "move-773be.firebaseapp.com",
	"messagingSenderId": "430505235194",
	"measurementId": "G-9YKZD5DTJ9"
};