export const colors = {
    LandingPage_Background: '#FFFFFF',
    LandingPage_Description: '#373737',
    Button_Primary: '#46F34D',
    Button_Secondary: '#D1A219', 
    Header_Background: "#FFFFFF",
    Header_Content: '#000000',
    Black: '#000000',
    Header: '#383C3D',
    Header_Text: "#FFFFFF",
    Header_Text_back: "#435152",
    Table_Header: '#BFCACD',
    Action_Button_Back: '#0A6AAD',
    ALERT_BUTTON_GOTO_COLOR: "#c6e2f6",
    ALERT_BUTTON_CLOSE_COLOR:  "#ea969d",
    HEADER: '#2a383b',
    BORDER_BACKGROUND: "#d6d6d6",
    CAR_BOX_EVEN:'#DEDFD5',
    CAR_BOX_ODD:'#f5bf19',
    CARD_LABEL:"#4C5152",
    CARD_DETAIL:"#F5F5F5",
    STATUS_COMPLETE:"#2CDE3A",
    STATUS_CANCELED:"#DF2E38",
    STATUS_PENDING:"##FFCD4B",
    ORANGE: "#FC4100",
    BLUE: "2C4E80",
  TAXIPRIMARY: "#06113C",
  TAXISECONDORY: "#DDDEEE",

  DELIVERYPRIMARY: "#00215E",
  DELIVERYSECONDORY: "#FFC55A",

  BIDTAXIPRIMARY: "#00564c",
  BIDTAXISECONDORY: "#eee7ca",

  WHITE: "#ffffff",
  BLACK: "#102920",
  RED: "#D41111",
  GREEN: "#00B234",
  YELLOW: "#FFAB1A ",
  FOOTERTOP: "#C8CDD0",
  HEADER: "#00215E",
  LIGHT_TEXT: "#415058",
  DARK_TEXT: "#1F292E",
  INACTIVE_TAB: "#2C4E8F",
}