import base64 from 'react-native-base64';
import { firebase } from '../config/configureFirebase';
import AccessKey from './MapAccessKey';

export const fetchPlacesAutocomplete = (searchKeyword, sessionToken) => {
    return new Promise((resolve,reject)=>{
        const { config } = firebase;
        const searchKey = encodeURIComponent(searchKeyword);
        fetch('https://maps.googleapis.com/maps/api/place/autocomplete/json?key=' + AccessKey + '&input=' + searchKey)
        .then(response => {
            return response.json();
        })
        .then(json => {
            if(json && json.predictions) {
                resolve(json.predictions);
            }else{
                reject(json.error);
            }
        }).catch(error=>{
            console.log("error" + error);
            reject("fetchPlacesAutocomplete Call Error")
        })    
        

    });
}

export const fetchCoordsfromPlace = (place_id) => {
    return new Promise((resolve,reject)=>{
        const { config } = firebase;        
        fetch('https://maps.googleapis.com/maps/api/geocode/json?place_id=' + place_id + '&key=' + AccessKey)
        .then(response => {
            return response.json();
        })
        .then(json => {
            if(json && json.results[0].geometry) {
                resolve(json.results[0].geometry["location"]);
            }else{
                reject(json.error);
            }
        }).catch(error=>{
            console.log("error" + error);
            reject("fetchCoordsfromPlace Call Error")
        })        
    });
}

export const fetchAddressfromCoords = (latlng) => { 
    return new Promise((resolve,reject)=>{
        const { config } = firebase;
    
        fetch('https://maps.googleapis.com/maps/api/geocode/json?address=' + latlng + '&key=' + AccessKey)
        .then(response => {
            return response.json();
        })
        .then(json => {
            if(json && json.results) {
                resolve(json.results[0].formatted_address);
            }else{
                reject(json.error);
            }
        }).catch(error=>{
            console.log("error" + error);
            reject("fetchAddressfromCoords Call Error")
        })
    });
}

export const getDistanceMatrix = (startLoc, destLoc) => {
    console.log("Distance Matrix - start loc: "+ startLoc + ' End loc: '+ destLoc)
    return new Promise((resolve,reject)=>{
        const { config } = firebase;
        fetch('https://maps.googleapis.com/maps/api/distancematrix/json?origins='+startLoc + '&destination=' + destLoc + '&key=' + AccessKey
        ).then(response => {
            return response.json();
        })
        .then(json => {
            if(json.error){
                console.log(json.error);
                reject(json.error);
            }else{
                resolve(json.data);
            }
        }).catch(error=>{
            console.log(error);
            reject("getDistanceMatrix Call Error")
        })
    });
}

export const getDirectionsApi = (startLoc, destLoc, waypoints) => {
    
   
    return new Promise((resolve,reject)=>{
        const { config } = firebase;
        const body = 'https://maps.googleapis.com/maps/api/directions/json?travel_mode=DRIVING&destination=' + destLoc + '&origin=' + startLoc +
        '&key=' + AccessKey;
        if(waypoints){
            body = 'https://maps.googleapis.com/maps/api/directions/json?travel_mode=DRIVING&destination=' + destLoc + '&origin=' + startLoc + '&waypoints=' +
        waypoints + '&key=' + AccessKey;
        }
        fetch(body)
        .then(response => {
            return response.json();
        })
        .then(json => {
            if (json.hasOwnProperty('routes')) {
                const route = {
                    distance_in_km:(json.routes[0].legs[0].distance.value / 1000),
                    time_in_secs:json.routes[0].legs[0].duration.value,
                    polylinePoints:json.routes[0].overview_polyline
                  };
                resolve(route);
            }else{
                console.log(json.error);
                reject(json.error);
            }
        }).catch(error=>{
            console.log(error);
            reject("getDirectionsApi Call Error")
        })
    });
}

